import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Hierarchy } from '../models/hierarchy.models';
import { BasicQuery } from '../models/misc';

@Injectable()
export class HierarchyService {

  constructor(private http: HttpClient) { }

  public query = (query: BasicQuery) => {
    return this.http.get<Hierarchy[]>(`${environment.apiUrl}/api/${query.projectId}/organization`, { params: { culture: query.culture, modelId: query.modelId ?? '' }});
  }
}
