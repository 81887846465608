import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import * as appSelectors from './app.selectors';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  
  private destroy$ = new Subject();

  public loading$!: Observable<boolean>;


  constructor(public store: Store, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {

    this.loading$ = this.store.pipe(select(appSelectors.selectLoading));

    this.store.pipe(select(appSelectors.selectSettings)).pipe(takeUntil(this.destroy$)).subscribe(settings => {

      // if(settings.theme.fontFamily != null) {
      //   this.document.body.classList.add(settings.theme.fontFamily);
      // }
      
      Object.keys(settings.theme.cssVariables).forEach(cssVariableName => {
        this.document.documentElement.style.setProperty(cssVariableName, settings.theme.cssVariables[cssVariableName]);
      });

    });

    this.document.documentElement.style.setProperty
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
