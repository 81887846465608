import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProjectItem } from '../models/project.models';

@Injectable()
export class ProjectService {

  constructor(private http: HttpClient) {}

  public get = () => {
    return this.http.get<Array<ProjectItem>>(`${environment.apiUrl}/api/project`);
  }

  public find = (projectId: string, query: { culture: string, flatHierarchies: boolean }) => {
    return this.http.get<ProjectItem>(`${environment.apiUrl}/api/project/${projectId}`, { params: query });
  }
}

