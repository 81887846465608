import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, dematerialize, distinctUntilChanged, first, last, mergeMap, switchMap, take, takeLast } from "rxjs";
import { previewModeFeature } from "../preview-mode.reducer";

@Injectable()
export class PreviewModeInterceptor implements HttpInterceptor {

  constructor(private store: Store) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return this.store.select(previewModeFeature.selectEnabled).pipe(
      first(),
        mergeMap((value) => {

            const clonedRequest = req.clone({
                setHeaders: {
                    Custom_preview_mode: value != null ? value.toString() : 'false',
                }
            });

            return next.handle(clonedRequest);
        }),
    );
  }  
}