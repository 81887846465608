import {
    ActionReducerMap,
    createFeatureSelector,
    MetaReducer,
  } from '@ngrx/store';
  import { environment } from '../../environments/environment';
  import * as fromApp from './app.reducer';
import { routerReducer, RouterState } from '@ngrx/router-store';
  
  export const appFeatureKey = 'app';
  
  export interface State {
    app: fromApp.State;
    router: RouterState
  }
  
  export const reducers: ActionReducerMap<State> = {
    app: fromApp.reducer,
    router: routerReducer,
  };
  
  export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
  
  export const selectAppFeature = createFeatureSelector<fromApp.State>(appFeatureKey);
  