import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CultureItem } from "../models/language.models";

@Injectable()
export class LanguageService {

  constructor(private http: HttpClient) { }

  public get = () => {
    return this.http.get<Array<CultureItem>>(`${environment.apiUrl}/api/translation`);
  }
}
