import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { withLatestFrom, map, EMPTY, catchError, mergeMap, combineLatest, filter } from "rxjs";
import * as  AppActions from '../actions/app.actions';
import * as  appSelectors from '../app.selectors';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { ProjectService } from "../modules/data-services/services/project.service";
import { LanguageService } from "../modules/data-services/services/language.service";
import { ROUTER_NAVIGATED, RouterNavigatedAction } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { LocalStorageService } from "../modules/core/services/local-storage.service";
import { TranslateService } from "@ngx-translate/core";
import { MetToastService } from "../modules/core/components/met-toast/met-toast.service";
import { SettingsService } from "../modules/data-services/services/settings.service";

@Injectable()
export class AppEffects {

    constructor(
        private actions$: Actions,
        private store: Store,
        private projects: ProjectService,
        private languages: LanguageService,
        private settings: SettingsService,
        private localStorage: LocalStorageService,
        private oidcSecurityService: OidcSecurityService,
        private translate: TranslateService,
        private toast: MetToastService,
        private router: Router) {}

    // authentication
    authUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.authUser),
            mergeMap(() => {
                return this.oidcSecurityService.checkAuth();
            })).pipe(
            map((loginResponse) => {
                if (!loginResponse.isAuthenticated) {
                    return AppActions.userUnauthorized();
                }

                return AppActions.authUserSuccess({ loginResponse: loginResponse });
            }),
        );
    });
  
    userUnauthorized$ = createEffect(() => this.actions$.pipe(
        ofType(AppActions.userUnauthorized),
        map(() => {
            this.oidcSecurityService.authorize();
        })), { dispatch: false });

    logout$ = createEffect(() => this.actions$.pipe(ofType(AppActions.logout),
        map((_action) => this.oidcSecurityService.logoff())
    ), {dispatch: false});

    profile$ = createEffect(() => this.actions$.pipe(ofType(AppActions.gotoProfile),
        map((_action) => window.location.href = environment.authSettings.authority),
    ), {dispatch: false});


    // load projects
    loadProjects$ = createEffect(() => this.actions$.pipe(
        ofType(AppActions.loadProjects),
        mergeMap(() => this.projects.get().pipe(
            mergeMap((projects) => [
                AppActions.loadProjectsSuccess({ projects }),
            ]),
            catchError(() => EMPTY)
        ))
    ));

    // determine project to load
    determineProjectToLoad$ = createEffect(() => combineLatest([
            this.actions$.pipe(ofType(AppActions.loadProjectsSuccess)),
        ])
    .pipe(
        map(([projectAction]) => {
            if(projectAction.projects.length === 0) {
                return AppActions.determineProjectToFailed();
            } 

            return AppActions.determineProjectToSuccess({ projectId: projectAction.projects[0].id })
        })
    ));

    // load languages
    loadLanguages$ = createEffect(() => this.actions$.pipe(
        ofType(AppActions.loadUILanguages),
        mergeMap(() => this.languages.get().pipe(
            mergeMap((languages) => [
                AppActions.loadUILanguagesSuccess({ languages }),
            ]),
            catchError(() => EMPTY)
        ))
    ));

    // load settings
    loadSettings$ = createEffect(() => this.actions$.pipe(
        ofType(AppActions.loadSettings),
        mergeMap(() => this.settings.get().pipe(
            mergeMap((settings) => [
                AppActions.loadSettingsSuccess({ settings }),
            ]),
            catchError(() => EMPTY)
        ))
    ));

    // effect to store default ui language in local storage
    onUILanguageChange$ = createEffect(() => this.translate.onLangChange.pipe(
        map(event => this.localStorage.set('uiLanguage', event.lang))
    ), { dispatch: false });

    // this makes sure it only tries to guess the current project if it's not in the url
    addRouteToHistory = createEffect(() => combineLatest([
            this.actions$.pipe(ofType(ROUTER_NAVIGATED)),
            this.actions$.pipe(ofType(AppActions.determineProjectToSuccess)),
        ])
        .pipe(
            withLatestFrom(this.store.select(appSelectors.selectProjects)),
            withLatestFrom(this.localStorage.get('latestProject')),
            filter(([[[routerAction,],],]) => (routerAction as RouterNavigatedAction).payload.routerState.url === '/'),
            map(([[[, projectAction], projects], latestProjectId]) => {
            const projectId =
                latestProjectId != null && projects.some(p => p.id === latestProjectId) ?
                (latestProjectId as string) : projectAction.projectId;

            return this.router.navigate(['project', projectId]);
            }),
        ), { dispatch: false });

    // Toast
    showSuccessToast$ = createEffect(() => this.actions$.pipe(
        ofType(AppActions.showSuccessToast),
        map((action) => this.toast.showToast({ text: action.text, type: "success" }))
    ), { dispatch: false });
}
