import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetSelectComponent, MetSelectOptionComponent } from './components/met-select/met-select.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocalStorageService } from './services/local-storage.service';
import { MetMultiProgressComponent } from './components/met-multi-progress/met-multi-progress.component';
import { MetTooltipDirective } from './components/met-tooltip/met-tooltip.directive';
import { MetTooltipComponent } from './components/met-tooltip/met-tooltip.component';
import { MetIconComponent } from './components/met-icon/met-icon.component';
import MetTabComponent, { MetTabsComponent } from './components/met-tab/met-tabs.component';
import { ScorePipe } from './pipes/score.pipe';
import { MetCardComponent } from './components/met-card/met-card.component';
import { MetAlertComponent } from './components/met-alert/met-alert.component';
import { MetCompareSelectionComponent } from './components/met-compare-selection/met-compare-selection.component';
import { MetToastModule } from './components/met-toast/met-toast.module';
import { MetButtonDirective } from './components/met-button/met-button.directive';
import { MetPageLoaderComponent } from './components/met-page-loader/met-page-loader.component';
import { NgChartsModule } from 'ng2-charts';
import { MetChartComponent } from './components/met-chart/met-chart.component';

@NgModule({
  declarations: [
    MetSelectComponent,
    MetSelectOptionComponent,
    MetMultiProgressComponent,
    MetTooltipDirective,
    MetTooltipComponent,
    MetIconComponent,
    MetTabsComponent,
    MetTabComponent,
    ScorePipe,
    MetCardComponent,
    MetAlertComponent,
    MetCompareSelectionComponent,
    MetButtonDirective,
    MetPageLoaderComponent,
    MetChartComponent,
   ],
  exports:[
    MetButtonDirective,
    MetPageLoaderComponent,
    MetSelectComponent,
    MetSelectOptionComponent,
    MetMultiProgressComponent,
    MetTooltipDirective,
    MetTooltipComponent,
    MetIconComponent,
    MetTabsComponent,
    MetTabComponent,
    MetCardComponent,
    MetAlertComponent,
    ScorePipe,
    MetCompareSelectionComponent,
    MetChartComponent,
  ],
  imports: [
    CommonModule,
    OverlayModule, 
    PortalModule, 
    FormsModule,
    NgChartsModule,
    MetToastModule.forRoot(),
    ReactiveFormsModule,
  ],
  providers: [
    LocalStorageService,
  ]
})
export class CoreModule { }
