import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserFilterItem, CreateFilter, FilterQuery, Filter, DeletFilter } from '../models/filter.models';

@Injectable()
export class FilterService {

  constructor(private http: HttpClient) {}

  public get = (query: { projectId: string, culture: string }) => {
    return this.http.get<Array<UserFilterItem>>(`${environment.apiUrl}/api/${query.projectId}/filter`, { params: { culture: query.culture }});
  }

  public create = (command: CreateFilter) => {
    return this.http.post<UserFilterItem>(`${environment.apiUrl}/api/${command.projectId}/filter`, command);
  }

  public find = (query: FilterQuery) => {
    return this.http.get<Filter>(`${environment.apiUrl}/api/${query.projectId}/filter/${query.id}`);
  }

  public delete = (command: DeletFilter): Observable<any> => {
    return this.http.delete<any>(`${environment.apiUrl}/api/${command.projectId}/filter/${command.filterId}`);
  }
}