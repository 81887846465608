import { createAction, props } from "@ngrx/store";
import { PreviewModeCheckResult } from "./preview-mode.models";
import { DialogRef } from "@angular/cdk/dialog";

export const checkPreviewMode = createAction(
    '[PreviewMode] checkPreviewMode',
)

export const checkPreviewModeSuccess = createAction(
    '[PreviewMode] checkPreviewModeSuccess',
    props<{ result: PreviewModeCheckResult }>()
)

export const checkPreviewModeFailed = createAction(
    '[PreviewMode] checkPreviewModeFailed',
    props<{ error: any }>()
)

// confirm preview mode
export const confirmPreviewMode = createAction(
    '[PreviewMode] confirmPreviewMode',
    props<{ dialogId: any }>()
);
  
export const confirmPreviewModeSuccess = createAction(
    '[PreviewMode] confirmPreviewModeSuccess',
    props<{ dialogId: any }>()
);

// reset mode
export const reset = createAction(
    '[PreviewMode] reset',
)

// clear
export const clear = createAction(
    '[PreviewMode] clear',
)
