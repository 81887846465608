import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { PreviewModeInterceptor } from '../project/modules/preview-mode/services/preview-mode.interceptor';
import { UserSwitchInterceptor } from '../project/modules/user-switch/services/user-switch.interceptor';

export const interceptorProviders =
   [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PreviewModeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserSwitchInterceptor, multi: true },
];
