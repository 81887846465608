import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CultureItem } from '../models/language.models';
import { BasicQuery } from '../models/misc';

@Injectable()
export class ProjectCultureService {

  constructor(private http: HttpClient) { }

  public get = (query: { projectId: string }) => {
    return this.http.get<Array<CultureItem>>(`${environment.apiUrl}/api/culture/${query.projectId}`);
  }
}
