import { createFeature, createReducer, on } from "@ngrx/store";
import * as actions from './user-switch.actions';

export interface State {
    saving: boolean;
    userSwitchAvailable: boolean;
    enabled: boolean;
    userId: string;
    checked: boolean;
  }
  
  export const initialState: State = {
    userSwitchAvailable: false,
    enabled: false,
    saving: false,
    userId: '',
    checked: false,
  };

  export const userSwitchFeature = createFeature({
    name: 'userswitch',
    reducer: createReducer(initialState,
      on(actions.checkUserSwitch, (state, action) => { 
        return { 
          ...state,
          saving: true,
        }
      }),

      on(actions.checkUserSwitchSuccess, (state, action) => { 
        return { 
          ...state,
          userSwitchAvailable: action.result.isAvailable,
          checked: true,
        }
      }),

      on(actions.checkUserSwitchFailed, (state, action) => { 
        return { 
          ...state,
          userSwitchAvailable: false,
        }
      }),

      on(actions.confirmUserSwitch, (state, action) => { 
        return { 
          ...state,
          saving: true,
        }
      }),

      on(actions.confirmUserSwitchSuccess, (state, action) => { 
        return { 
          ...state,
          saving: false,
          enabled: true,
          userId: action.userId,
        }
      }),

      on(actions.reset, (state, action) => { 
        return {
          ...initialState,
          enabled: false,
          userId: '',
        }
      }),

      on(actions.clear, (state, action) => { 
        return {
          ...initialState,
          enabled: state.enabled,
          userId: state.userId,
        }
      }),
    ),
  });