import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Dataset } from '../models/datasets.models';

@Injectable()
export class DatasetService {

  constructor(private http: HttpClient) { }

  public query = (query: { projectId: string, culture: string }) => {
    return this.http.get<Array<Dataset>>(`${environment.apiUrl}/api/${query.projectId}/dataset`, { params: { culture: query.culture }});
  }
}
