import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BasicQuery } from '../models/misc';
import { ProjectItem } from '../models/project.models';
import { Settings } from '../models/settings.models';

@Injectable()
export class SettingsService {

  constructor(private http: HttpClient) {}

  public get = () => {
    return this.http.get<Settings>(`${environment.apiUrl}/api/settings`);
  }
}

