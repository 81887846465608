import { createReducer, on } from '@ngrx/store';
import * as appActions from '../actions/app.actions';
import { LoginResponse } from 'angular-auth-oidc-client';
import { ProjectItem } from '../modules/data-services/models/project.models';
import { CultureItem } from '../modules/data-services/models/language.models';
import { Settings } from '../modules/data-services/models/settings.models';

export interface State {
  user?: LoginResponse;
  uiLanguagesLoading: boolean;
  uiLanguages: CultureItem[];
  currentUILanguage?: CultureItem;
  projectsLoading: boolean;
  projects: Array<ProjectItem>;
  settingsLoading: boolean;
  settings?: Settings;
}

export const initialState: State = {
  user: undefined,
  uiLanguagesLoading: false,
  currentUILanguage: undefined,
  uiLanguages: [],
  projectsLoading: false,
  projects: [],
  settingsLoading: false,
  settings: undefined,
};

export const reducer = createReducer(initialState,

  on(appActions.authUserSuccess, (state, action) => ({ ...state, user: action.loginResponse })),

  // load projects
  on(appActions.loadProjects, (state, action) => ({ ...state, projectsLoading: true })),
  on(appActions.loadProjectsSuccess, (state, action) => ({ ...state, projectsLoading: false, projects: action.projects })),
  
  // load languages
  on(appActions.loadUILanguages, (state, action) => ({ ...state, uiLanguagesLoading: true })),
  on(appActions.loadUILanguagesSuccess, (state, action) => ({ ...state, uiLanguagesLoading: false, uiLanguages: action.languages })),

  // load settings
  on(appActions.loadSettings, (state, action) => ({ ...state, settingsLoading: true })),
  on(appActions.loadSettingsSuccess, (state, action) => ({ ...state, settingsLoading: false, settings: action.settings })),
  

  // set ui language
  on(appActions.setUILanguage, (state, action) => ({ ...state, currentUILanguage: action.language })),
);
