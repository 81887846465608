import { NgModule, ModuleWithProviders } from '@angular/core'
import { OverlayModule } from '@angular/cdk/overlay';

import { MetToastComponent } from './met-toast.component';
import { MET_TOAST_CONFIG, defaultToastConfig } from './met-toast.config';
import { MetToastService } from './met-toast.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [OverlayModule, CommonModule],
  declarations: [MetToastComponent],
  providers:[MetToastService],
})
export class MetToastModule {
  public static forRoot(config = defaultToastConfig): ModuleWithProviders<MetToastModule> {
        return {
            ngModule: MetToastModule,
            providers: [
                {
                    provide: MET_TOAST_CONFIG,
                    useValue: { ...defaultToastConfig, ...config },
                },
            ],
        };
    }
 }