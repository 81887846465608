import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class WavesTranslateLoader implements TranslateLoader  {

  constructor(private http: HttpClient) {}

  public getTranslation(lang: string): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}/api/translation/${lang}`);
  }
}
