import { createFeature, createReducer, on } from "@ngrx/store";
import * as actions from './preview-mode.actions';

export interface State {
    loading: boolean;
    saving: boolean;
    previewModeAvailable: boolean;
    enabled: boolean;
  }
  
  export const initialState: State = {
    loading: false,
    previewModeAvailable: false,
    enabled: false,
    saving: false,
  };

  export const previewModeFeature = createFeature({
    name: 'previewmode',
    reducer: createReducer(initialState,
      on(actions.checkPreviewMode, (state, action) => { 
        return { 
          ...state,
          loading: true,
        }
      }),

      on(actions.checkPreviewModeSuccess, (state, action) => { 
        return { 
          ...state,
          loading: false,
          previewModeAvailable: action.result.isAvailable,
        }
      }),

      on(actions.checkPreviewModeFailed, (state, action) => { 
        return { 
          ...state,
          loading: false,
          previewModeAvailable: false,
        }
      }),

      on(actions.confirmPreviewMode, (state, action) => { 
        return { 
          ...state,
          saving: true,
        }
      }),

      on(actions.confirmPreviewModeSuccess, (state, action) => { 
        return { 
          ...state,
          saving: false,
          enabled: true,
        }
      }),

      on(actions.reset, (state, action) => { 
        return {
            ...initialState,
            enabled: false,
        }            
      }),

      on(actions.clear, (state, action) => { 
        return {
            ...initialState,
            enabled: state.enabled,
        }            
      }),
    ),
  });