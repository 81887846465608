import { createAction, props } from "@ngrx/store";
import { LoginResponse } from "angular-auth-oidc-client";
import { ProjectItem } from "../modules/data-services/models/project.models";
import { CultureItem } from "../modules/data-services/models/language.models";
import { Settings } from "../modules/data-services/models/settings.models";

export const authUser = createAction(
    '[App] authUser',
);

export const userUnauthorized = createAction(
    '[App] userUnauthorized',
);

export const authUserSuccess = createAction(
    '[App] authUserSuccess',
    props<{ loginResponse: LoginResponse }>()
);

export const logout = createAction(
    '[App] logout',
)

export const gotoProfile = createAction(
    '[App] gotoProfile',
)

// load projects
export const loadProjects = createAction(
    '[App] loadProjects',
);

export const loadProjectsSuccess = createAction(
    '[App] loadProjectsSuccess',
    props<{ projects: ProjectItem[] }>()
);

// load settings
export const loadSettings = createAction(
    '[App] loadSettings',
);

export const loadSettingsSuccess = createAction(
    '[App] loadSettingsSuccess',
    props<{ settings: Settings }>()
);


// determine project to load
export const determineProjectToLoad = createAction(
    '[App] determineProjectToLoad',
    props<{ projects: ProjectItem[] }>()
)

export const determineProjectToSuccess = createAction(
    '[App] determineProjectToSuccess',
    props<{ projectId: string }>()
)

export const determineProjectToFailed = createAction(
    '[App] determineProjectToFailed',
)

// load available UI languages
export const loadUILanguages = createAction(
    '[App] loadUILanguages',
);

export const loadUILanguagesSuccess = createAction(
    '[App] loadUILanguagesSuccess',
    props<{ languages: CultureItem[] }>()
);

export const loadUILanguagesFailed = createAction(
    '[App] loadUILanguagesFailed',    
);

// set UI language
export const setUILanguage = createAction(
    '[App] setUILanguage',
    props<{ language: CultureItem }>()
);

// toasts
export const showSuccessToast = createAction(
    '[App] showSuccessToast',
    props<{ text: string }>()

)