import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';


@NgModule({
    imports: [AuthModule.forRoot({
        config: environment.authSettings,
      })],
    exports: [AuthModule],
})
export class AuthConfigModule {}
