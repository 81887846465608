import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { authUser, loadProjects, loadSettings, loadUILanguages } from '../actions/app.actions';
import { selectProjects, selectSettings, selectUILanguages, selectUser } from '../app.selectors';
import { filter } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Initializer {

  constructor(private store: Store, private translate: TranslateService) { }

  public initUser = () => {
    this.store.dispatch(authUser());

    return new Promise((resolver) => {
        this.store.select(selectUser).pipe(filter(d => d != null)).subscribe((result) => resolver(result));
    })
  }

  public initProjects = () => {
    this.store.dispatch(loadProjects());

    return new Promise((resolver) => {
      this.store.select(selectProjects).pipe(filter(d => d != null && d.length > 0)).subscribe(result => resolver(result));
    });
  }

  public initSettings = () => {
    this.store.dispatch(loadSettings());

    return new Promise((resolver) => {
      this.store.select(selectSettings).pipe(filter(d => d != null)).subscribe(result => resolver(result));
    });
  }

  public initLanguaes = () => {
    this.store.dispatch(loadUILanguages());

    return new Promise((resolver) => {
      this.store.select(selectUILanguages).pipe(filter(d => d != null && d.length > 0)).subscribe(result => resolver(result));
    });
  }

  public initDefaultLanguage = () => {
    this.translate.setDefaultLang('en');
  }
}
