import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { AuthConfigModule } from './auth/auth-config.module';
import { interceptorProviders } from './modules/core/interceptors';
import { AppEffects } from './effects/app.effects';
import { metaReducers, reducers } from './reducers';
import { DataServicesModule } from './modules/data-services/data-services.module';
import { LayoutModule } from './modules/layout/layout.module';
import { CoreModule } from './modules/core/core.module';
import { WavesTranslateLoader } from './modules/core/services/translate-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Initializer } from './services/initializer.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgChartsModule,
    AuthConfigModule,
    DataServicesModule,
    CoreModule,
    LayoutModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([AppEffects]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'routerState'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    Initializer,
    interceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [Initializer],
      name: 'Init',
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function initApp(initializer: Initializer): () => Promise<any> {
  return async (): Promise<any> => {
    
    await initializer.initUser();

    initializer.initDefaultLanguage();

    return await Promise.all([
      initializer.initSettings(),
      initializer.initProjects(),
      initializer.initLanguaes(),
    ]);
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new WavesTranslateLoader(http);
}
  