import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: 'project/:projectId',
    loadChildren: () => import('.//modules/project/project.module').then(m => m.ProjectModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule],
})
export class AppRoutingModule { }