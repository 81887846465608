import { OverlayRef } from "@angular/cdk/overlay";
import { InjectionToken } from "@angular/core";

export interface MetToastData {
    text?: string;
    type: ToastType;
    title?: string;
}

export interface MetToastConfig {
    duration?: number;
    position?: ToastPosition;
}

export const defaultToastConfig: MetToastConfig = {
    duration: 3000,
    position: "topRight",
}

export type ToastType = 'warning' | 'info' | 'success' | 'error';
export type ToastPosition = 'bottomRight' | 'bottomLeft' | 'topRight' | 'topLeft';

export const MET_TOAST_CONFIG = new InjectionToken<MetToastConfig>('MET_TOAST_CONFIG');
export const MET_TOAST_DATA = new InjectionToken<MetToastData>('MET_TOAST_DATA');

export type ToastAnimationState = 'default' | 'closing';

export class ToastRef {
    constructor(private readonly overlay: OverlayRef) { }
  
    close() {
      this.overlay.detach();
      this.overlay.dispose();
    }
  
    isVisible() {
      return this.overlay && this.overlay.overlayElement;
    }
  
    getPosition() {
      return this.overlay.overlayElement.getBoundingClientRect()
    }
  }