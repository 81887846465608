import { createAction, props } from "@ngrx/store";
import { UserSwitchCheckResult } from "./user-switch.models";

export const checkUserSwitch = createAction(
    '[UserSwitch] checkUserSwitch',
    props<{ userId: string, dialogId: any }>()
)

export const checkUserSwitchSuccess = createAction(
    '[UserSwitch] checkUserSwitchSuccess',
    props<{ result: UserSwitchCheckResult, userId: string }>()
)

export const checkUserSwitchFailed = createAction(
    '[UserSwitch] checkUserSwitchFailed',
    props<{ error: any }>()
)

// confirm preview mode
export const confirmUserSwitch = createAction(
    '[UserSwitch] confirmUserSwitch',
    props<{ userId: string, dialogId: any }>()
);
  
export const confirmUserSwitchSuccess = createAction(
    '[UserSwitch] confirmUserSwitchSuccess',
    props<{ userId: string, dialogId: any }>()
);

// reset
export const reset = createAction(
    '[UserSwitch] reset',
)

// clear
export const clear = createAction(
    '[UserSwitch] clear',
)
