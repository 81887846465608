import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class LocalStorageService {
  localStorage: Storage;

  constructor() {
    this.localStorage = window.localStorage;
  }

  get(key: string): Observable<any> {
    if (this.isLocalStorageSupported) {
      const item = this.localStorage.getItem(key);

      return (item != null && item != "undefined") ? of(JSON.parse(this.localStorage.getItem(key)!)) : of(null);
    }
    return of(null);
  }

  set(key: string, value: any): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key);
      return true;
    }
    return false;
  }

  get isLocalStorageSupported(): boolean {
    return !!this.localStorage;
  }
}
