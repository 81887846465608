import { Inject, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MetToastComponent } from './met-toast.component';
import { MetToastData, MET_TOAST_CONFIG, MetToastConfig, ToastRef, MET_TOAST_DATA, defaultToastConfig } from './met-toast.config';

@Injectable()
export class MetToastService {

  lastToast?: ToastRef;

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    @Inject(MET_TOAST_CONFIG) private toastConfig: MetToastConfig,
  ) { }

  showToast(data: MetToastData, config: MetToastConfig = defaultToastConfig): OverlayRef {

    const overlayRef = this.overlay.create(this.getOverlayConfig());

    const toastRef = new ToastRef(overlayRef);

    this.lastToast = toastRef;

    const customInjector = Injector.create({
      providers: [
        { provide: MET_TOAST_CONFIG, useValue: { ...this.toastConfig, ...config }}, 
        { provide: ToastRef, useValue: toastRef },
        { provide: MET_TOAST_DATA, useValue: data }
      ],
      parent: this.injector,
    });
    
    const toastPortal = new ComponentPortal(MetToastComponent, null, customInjector);
    overlayRef.attach(toastPortal);

    return overlayRef;
  }

  private getOverlayConfig(): OverlayConfig {


    return new OverlayConfig({
      hasBackdrop: false,
      positionStrategy: this.getPosition(),
    });
  }

  getPosition() {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();

    if(this.toastConfig.position === 'topRight') {

      const position = lastToastIsVisible 
      ? this.lastToast?.getPosition().bottom
      : 20;

      return this.overlay.position().global().top(position+'px').right('20px');
    }

    if(this.toastConfig.position === 'bottomRight') {

      const position = lastToastIsVisible 
      ? this.lastToast?.getPosition().top
      : 20;

      return this.overlay.position().global().bottom(position+'px').right('20px');
    }

    if(this.toastConfig.position === 'topLeft') {

      const position = lastToastIsVisible 
      ? this.lastToast?.getPosition().bottom
      : 20;

      return this.overlay.position().global().top(position+'px').left('20px');
    }

    const position = lastToastIsVisible 
    ? this.lastToast?.getPosition().top
    : 20;

    return this.overlay.position().global().bottom(position+'px').left('20px');
  }
}
