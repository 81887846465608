import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnonymityCheckService } from './services/anonymity-check.service';
import { BenchmarkService } from './services/benchmark.service';
import { DatasetService } from './services/dataset.service';
import { DemographicService } from './services/demographic.service';
import { DimensionService } from './services/dimension.service';
import { FilterService } from './services/filter.service';
import { HierarchyService } from './services/hierarchy.service';
import { LanguageService } from './services/language.service';
import { ProjectService } from './services/project.service';
import { ProjectCultureService } from './services/projectculture.service';
import { StatementService } from './services/statement.service';
import { SettingsService } from './services/settings.service';

const DATA_SERVICES = 
[
  AnonymityCheckService,
  BenchmarkService,
  DatasetService,
  DemographicService,
  DimensionService,
  FilterService,
  HierarchyService,
  LanguageService,
  ProjectService,
  SettingsService,
  ProjectCultureService,
  StatementService
]

@NgModule({
  declarations: [],
  exports:[],
  imports: [
    CommonModule,
  ],
  providers: [DATA_SERVICES],
})
export class DataServicesModule { }
