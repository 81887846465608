// src/app/toast/toast.component.ts
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MET_TOAST_CONFIG, MET_TOAST_DATA, MetToastConfig, MetToastData, ToastAnimationState, ToastRef } from './met-toast.config';
import { trigger, transition, style, animate, state, AnimationEvent } from '@angular/animations';

@Component({
  selector: 'app-toast',
  templateUrl: './met-toast.component.html',
  styleUrls: ['./met-toast.component.css'],
  animations: [
    trigger('metToastAnimation', [
      state('default', style({ opacity: 1, transform: 'translateY(0.5rem)' })),
      transition('void => *', [style({ opacity: 0, transform: 'translateY(0px)' }), animate('300ms ease-out')]),
      transition(
          'default => closing',
          animate('200ms ease-in', style({ opacity: 0 })),
      ),
  ]),
  ],
})
export class MetToastComponent implements OnInit, OnDestroy {

  animationState: ToastAnimationState = 'default';

  private intervalId?: any;

  constructor(
    readonly ref: ToastRef,
    @Inject(MET_TOAST_CONFIG) public config: MetToastConfig,
    @Inject(MET_TOAST_DATA) public data: MetToastData) { }

  ngOnInit() {
    this.intervalId = setTimeout(() => this.animationState = 'closing', this.config.duration);
  }

  close() {
    this.ref.close();
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.intervalId);
  }
}