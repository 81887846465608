import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Benchmark } from '../models/benchmarks.models';
import { BasicQuery } from '../models/misc';

@Injectable()
export class BenchmarkService {

  constructor(private http: HttpClient) { }

  public query = (query: BasicQuery) => {
    return this.http.get<Benchmark[]>(`${environment.apiUrl}/api/${query.projectId}/benchmark`, { params: { culture: query.culture! } });
  }
}