import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DimensionItem } from '../models/dimensions.models';
import { BasicQuery } from '../models/misc';

@Injectable()
export class DimensionService {

  constructor(private http: HttpClient) { }

  public query = (query: BasicQuery) => {
    return this.http.get<Array<DimensionItem>>(`${environment.apiUrl}/api/${query.projectId}/dimension`, { params: { culture: query.culture } });
  }
}
