import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StatementItem } from '../models/statement.models';
import { BasicQuery } from '../models/misc';

@Injectable()
export class StatementService {

  constructor(private http: HttpClient) { }

  public query = (query: BasicQuery) => {
    return this.http.get<Array<StatementItem>>(`${environment.apiUrl}/api/${query.projectId}/statement`, { params: { culture: query.culture }});
  }
}

