import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, combineLatest, filter, first, mergeMap, take } from "rxjs";
import { userSwitchFeature } from "../user-switch.reducer";

@Injectable()
export class UserSwitchInterceptor implements HttpInterceptor {

  constructor(private store: Store) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return this.store.select(userSwitchFeature.selectUserId).pipe(
      first(),
      mergeMap((value) => {

          const clonedRequest = req.clone({
              setHeaders: {
                Custom_user_id: value != null && value.length > 0 ? value : '',
              }
          });

          return next.handle(clonedRequest);
      })
    );
  }
}